// // old dele prod env
// let apiRoot = 'https://app.dele.ai/api/v4'
// let socketRoot = 'wss://secure.mqtt.dele.ai'
// // new BE system
// let authenticationApiRoot = 'https://authentication-api.live.dele.ai'
// let keycloakApiRoot = 'https://keycloak.live.dele.ai'
// let fcmApiRoot = 'https://fcm-api.live.dele.ai'
// let customerMenagementApiRoot = 'https://customer-management-api.live.dele.ai'
// let notificationApiRoot = 'https://notifications-api.live.dele.ai'
// let eventRulesetApiRoot = 'https://ruleset-api.live.dele.ai'
// let fcmTopicRegion = 'dbm_eu_'

// vayyar dele dev env
let apiRoot = 'https://cloud-backend.dev.dele.ai/api/v4'
let socketRoot = 'wss://dbm-eu-secure.mqtt.dev.dele.ai'
// new BE system
let authenticationApiRoot = 'https://cloud-backend.dev.dele.ai'
let keycloakApiRoot = 'https://dbm-eu-keycloak.dev.dele.ai'
let fcmApiRoot = 'https://cloud-backend.dev.dele.ai'
let customerMenagementApiRoot = 'https://cloud-backend.dev.dele.ai'
let notificationApiRoot = 'https://cloud-backend.dev.dele.ai'
let eventRulesetApiRoot = 'https://cloud-backend.dev.dele.ai'
let fcmTopicRegion = 'dbm_eu_'

// TODO(Milica): Switch these before deploy!!

// if we've added .env files to the vue root
if (process.env.VUE_APP_API_ROOT) {
  apiRoot = process.env.VUE_APP_API_ROOT
}
if (process.env.VUE_APP_SOCKET_ROOT) {
  socketRoot = process.env.VUE_APP_SOCKET_ROOT
}

if (process.env.VUE_APP_AUTHENTICATION_API_ROOT) {
  authenticationApiRoot = process.env.VUE_APP_AUTHENTICATION_API_ROOT
}
if (process.env.VUE_APP_KEYCLOAK_API_ROOT) {
  keycloakApiRoot = process.env.VUE_APP_KEYCLOAK_API_ROOT
}
if (process.env.VUE_APP_FCM_API) {
  fcmApiRoot = process.env.VUE_APP_FCM_API
}
if (process.env.VUE_APP_CUSTOMER_MENAGEMENT) {
  customerMenagementApiRoot = process.env.VUE_APP_CUSTOMER_MENAGEMENT
}
if (process.env.VUE_APP_NOTIFICATION_API_ROOT) {
  notificationApiRoot = process.env.VUE_APP_NOTIFICATION_API_ROOT
}
if (process.env.VUE_APP_EVENT_RULESET) {
  eventRulesetApiRoot = process.env.VUE_APP_EVENT_RULESET
}
if (process.env.VUE_APP_FCM_TOPIC_REGION) {
  fcmTopicRegion = process.env.VUE_APP_FCM_TOPIC_REGION
}

export const API_ROOT = apiRoot
export const SOCKET_ROOT = socketRoot

export const AUTHENTICATION_API_ROOT = authenticationApiRoot
export const KEYCLOAK_API_ROOT = keycloakApiRoot
export const FCM_API_ROOT = fcmApiRoot
export const CUSTOMER_MENAGEMENT_API_ROOT = customerMenagementApiRoot
export const NOTIFICATION_API_ROOT = notificationApiRoot
export const EVENT_RULESET_API_ROOT = eventRulesetApiRoot
export const FCM_TOPIC_REGION = fcmTopicRegion
