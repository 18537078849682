import EventBus from '@/components/EventBus/EventBus'

export function ErrMsg (inputTxt) {
  EventBus.$emit('snackbar', {
    title: 'Error',
    text: inputTxt,
    variant: 'red',
    solid: true,
    location: 'bottom-left',
    hideDelay: 5000
  })
}

export function SuccMsg (inputTxt, link) {
  EventBus.$emit('snackbar', {
    title: 'Success',
    text: inputTxt,
    variant: 'green',
    solid: true,
    location: 'bottom-left',
    hideDelay: 5000,
    link: link
  })
}

export function CustomMsg (inputTxt) {
  EventBus.$emit('snackbar', {
    title: 'Error',
    text: inputTxt,
    variant: 'red',
    solid: true,
    location: 'bottom-left',
    hideDelay: -1,
    width: 480
  })
}
