<template>
  <div class="sidebar-bg full-width" >
    <div class="pages-menu">
      <b-list-group class="sb-list">
        <div class="spacer"> </div>

        <router-link to="/alarms"><b-list-group-item><b-icon-alarm /> <span class="sb-text">{{$t('navigation.alarms')}}</span></b-list-group-item></router-link>

        <router-link to="/containers"><b-list-group-item ><b-icon-bullseye /><span class="sb-text"> {{$t('navigation.dashboard')}}</span></b-list-group-item></router-link>

        <router-link v-if="isAdmin || editorNonAdmin" to="/facility"><b-list-group-item><b-icon-building /> <span class="sb-text">{{$t('navigation.facility')}}</span></b-list-group-item></router-link>
        <router-link v-if="isAdmin || editorNonAdmin" to="/device-registration"><b-list-group-item><b-icon-grid /> <span class="sb-text">{{$t('navigation.deviceReg')}}</span></b-list-group-item></router-link>
        <router-link v-if="isAdmin || editorNonAdmin" to="/device-inventory"><b-list-group-item><b-icon-box-seam/> <span class="sb-text">{{$t('navigation.deviceInventory')}}</span></b-list-group-item></router-link>

        <router-link to="/alerts"><b-list-group-item><b-icon-box-seam /><span class="sb-text"> {{$t('navigation.alert')}}</span></b-list-group-item></router-link>
        <router-link v-if="higherAdmin" to="/audit"><b-list-group-item><b-icon-box-seam /><span class="sb-text"> {{$t('navigation.eventLog')}}</span></b-list-group-item></router-link>
        <router-link to="/user-management"><b-list-group-item><b-icon-person-badge /><span class="sb-text"> {{$t('navigation.userManagement')}}</span></b-list-group-item></router-link>

        <router-link v-if="higherAdmin"  to="/accounts-management"><b-list-group-item><b-icon-person-badge /><span class="sb-text"> {{$t('navigation.accountsManagement')}}</span></b-list-group-item></router-link>
        <router-link v-if="showNotification"  to="/notifications"><b-list-group-item><b-icon-bell /><span class="sb-text"> {{$t('navigation.notifications')}}</span></b-list-group-item></router-link>

        <router-link to="/pauses"><b-list-group-item><b-icon-person-badge /><span class="sb-text"> {{$t('navigation.pause')}}</span></b-list-group-item></router-link>
        <div class="spacer"> </div>
      </b-list-group>
      <div>
        <b-list-group class="bottom-sidebar full-width sb-list" >
          <div @click="doLogout()">
            <b-list-group-item>
              <b-icon-box-arrow-in-left />
              <span class="sb-text"> {{$t('navigation.logout')}}</span>
            </b-list-group-item>
          </div>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Sidebar',
  computed: {
    ...mapGetters({
      getAlarmMessages: 'devices/getAlarmMessages',
      getWarningMessages: 'devices/getWarningMessages',
      isAdmin: 'login/isAdmin',
      myInfo: 'login/myInfo',
      getKnownAdminRoles: 'getKnownAdminRoles'
    }),
    higherAdmin: function () {
      return this.myInfo?.roles?.some(role => ['FULFILLER_ADMIN', 'SUPERADMIN'].includes(role))
    },
    hasAdminRole: function () {
      return this.myInfo?.roles?.some(role => this.getKnownAdminRoles?.includes(role))
    },
    editorNonAdmin: function () {
      return this.myInfo?.roles?.some(role => ['FULFILLER_USER'].includes(role))
    },
    totalAlarms: function () {
      return this.getAlarmMessages.length
    },
    totalWarnings: function () {
      return this.getWarningMessages.length
    },
    showNotification: function () {
      let currentURL = window.location.href
      let shouldShowNotification = (currentURL.includes('.dele.ai') || currentURL.includes('localhost')) && !currentURL.includes('dbm-us')
      return this.hasAdminRole && shouldShowNotification
    }
  },
  methods: {
    ...mapActions({
      doLogout: 'login/doLogout'
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/sidebar.scss';
.list-group-item {
  padding-top: 8px ;
  padding-bottom: 8px ;
  font-size: 15px;
}
</style>
