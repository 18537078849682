import { ErrMsg, SuccMsg } from '@/components/notifications/_notifications'
import {
  crudMsg,
  serverMsg,
  deviceMsg
} from '@/components/notifications/_message_text'

export default function CrudMsgProcessor (type, msg) {
  switch (type) {
    case 'container_update':
      SuccMsg(crudMsg.Container.updateSuccess(msg))
      break
    case 'container_create':
      SuccMsg(crudMsg.Container.createSuccess(msg))
      break
    case 'remove_container':
      SuccMsg(crudMsg.Container.removeSuccess(msg))
      break
    case 'device_add':
      SuccMsg(crudMsg.Device.addSuccess(msg))
      break
    case 'device_delete':
      SuccMsg(crudMsg.Device.deleteSuccess(msg))
      break
    case 'remove_device':
      SuccMsg(crudMsg.Device.removeSuccess(msg))
      break
    case 'device_upload':
      SuccMsg(crudMsg.Device.uploadSuccess)
      break
    case 'amplifyDL':
      SuccMsg(crudMsg.Container.amplify(msg))
      break
    case 'createUser':
      SuccMsg(crudMsg.User.createUser(msg))
      break
    case 'editedRole':
      SuccMsg(crudMsg.User.editedRole(msg))
      break
    case 'deletedUser':
      SuccMsg(crudMsg.User.deletedUser(msg))
      break
    case 'editCurrUser':
      SuccMsg(crudMsg.Container.updateSuccess(msg))
      break
    case 'notificationReceived':
      SuccMsg(crudMsg.Device.notificationReceived(msg))
      break
    case 'createUserErr':
      ErrMsg(crudMsg.User.createUserErr(msg))
      break
    case 'editedRoleErr':
      ErrMsg(crudMsg.User.editedRoleErr(msg))
      break
    case 'deletedUserErr':
      ErrMsg(crudMsg.User.deletedUserErr(msg))
      break
    case 'editCurrUserErr':
      ErrMsg(crudMsg.User.editCurrUserErr(msg))
      break
    case 'successfullyUpdatedRuleset':
      SuccMsg(crudMsg.User.updatedRuleset(msg))
      break
    case 'simulateSmartAlertError':
      ErrMsg(deviceMsg.simulateSoftAlertError())
      break
    case 'err':
      if (msg?.response?.status === 500) {
        // TODO: Handle All Possible Error Statuses
        ErrMsg(serverMsg.Server.generalError())
      } else {
        console.log('Unknown Error', msg)
        ErrMsg(serverMsg.Server.generalError(msg))
      }
      break
    default:
      break
  }
}
